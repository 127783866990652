$(function () {
    var header = $("#header-main");
    var intViewportHeight = window.innerHeight;
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if (scroll >= 10) {
            header.addClass("wide");
        } else {
            header.removeClass("wide");
        }

        if (scroll >= 200) {
            header.addClass("small");
        } else {
            header.removeClass("small");
        }


        if (scroll >= intViewportHeight) {
            header.addClass("normal");
        } else {
            header.removeClass("normal");
        }
    });
});



// Accordion-Menu

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");

$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open');
});



// Press "ESC"

$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $('#lang-select > ul > li:first-child, #lang-select > ul > li >ul, .download-lang-select > ul > li:first-child, .download-lang-select > ul > li >ul').removeClass("active");
    }
});

$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});



// OffCanvas

$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");

    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});



// Menu
$('.nav-main > ul > li > ul').each(function(i, obj) {
    if (obj.children.length > 0) {
        $(this).parent().addClass('has-subnav')
    }
});

$(".nav-main ul li.has-subnav").mouseover(function () {
    $(".overlay").addClass("menu-active");
});

$(".nav-main").mouseout(function () {
    $(".overlay").removeClass("menu-active");
});


// Lang-Select

$('#lang-select > ul > li:first-child').click(function () {
    $(this).toggleClass("active");
    $(this).find('ul').toggleClass("active");
});




// Download Lang-Select

$('.download-lang-select > ul > li:first-child').click(function () {
    $(this).toggleClass("active");
    $(this).find('ul').toggleClass("active");
});



// Slider-Start-Top

$(".slider-start-content-wrapper").slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: false,
    responsive: [
        {
            breakpoint: 860,
            settings: {
                autoplay: true,
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
});



// Slider3Col

$(".slider-2col").slick({
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: false,
    speed: 500,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
        {
            breakpoint: 860,
            settings: {
                autoplay: true,
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
})
.on('setPosition', function (event, slick) {
    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
});


$(".slider-3col").slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: false,
    speed: 500,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
        {
            breakpoint: 1023,
            settings: {
                autoplay: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 860,
            settings: {
                slidesToShow: 1,
                arrows: false
            }
        }
    ]
})
.on('setPosition', function (event, slick) {
    slick.$slides.css('height', slick.$slideTrack.height() + 'px');
});



// Slider Gallery

$(".slider-gallery").slick({
    variableWidth: true,
    focusOnSelect: true,
    centerMode: true,
    arrows: false,
    autoplay: true,
});




// Slider Product Detail

$('.prod-detail-top-slider').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    asNavFor: '.prod-detail-top-slider-nav'
});

$('.prod-detail-top-slider-nav').slick({
    arrows: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    asNavFor: '.prod-detail-top-slider',
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 639,
            settings: {
                slidesToShow: 2
            }
        }
    ]
});



// Historie Nav

$(function () {
    var historieNav = $(".nav-historie");
    $(window).scroll(function () {
        var scroll = $(window).scrollTop();

        if ($(window).width() < 1023) {
            if (scroll >= 400) {
                historieNav.addClass("sticky");
            } else {
                historieNav.removeClass("sticky");
            }
        }
        else if ($(window).width() < 859) {
            if (scroll >= 360) {
                historieNav.addClass("sticky");
            } else {
                historieNav.removeClass("sticky");
            }
        }
        else if ($(window).width() < 639) {
            if (scroll >= 300) {
                historieNav.addClass("sticky");
            } else {
                historieNav.removeClass("sticky");
            }
        }
        else {
            if (scroll >= 500) {
                historieNav.addClass("sticky");
            } else {
                historieNav.removeClass("sticky");
            }
        }
    });
});

$(".nav-historie>li").click(function() {
    $(".nav-historie>li").removeClass("active");
    $(this).addClass("active");
});




// Filter

$(".filter-select-wrapper ul").click(function () {
    $(this).toggleClass("open");
});




// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();

                if ($(window).width() > 860) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 195
                    }, 1500);
                } else {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 120
                    }, 1500);
                }
            }
        }
    });


// Bezugsquellen

$('.map-input').click(function() {
    $(".map-wrapper.bezugsquellen").addClass('active');
    $(".bezugsquellen-result-wrapper").addClass('show');
});